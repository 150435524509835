
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { TermsPersonalService } from "../../services/Terms/TermsPersonalService";


interface ContextProps {
    isLoadingFile: boolean,
    termsPersonal: any,
    setTermsPersonal: Dispatch<SetStateAction<any>>,
    params: any,
    setParams: Dispatch<SetStateAction<any>>,
    pages: PagesPaginateModel,
    setPages: Dispatch<SetStateAction<PagesPaginateModel>>,
    isLoading: boolean,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    handleList: () => void,
    handleSaveFile: (file: any, id: number) => void,
    handleStartDocument: (data: any) => void,
}

export const TermsPersonalContext = createContext<ContextProps>({} as ContextProps);

interface TermsPersonalProviderProps {
    children: ReactNode;
}

export const TermsPersonalProvider = ({ children }: TermsPersonalProviderProps) => {
    const [termsPersonal, setTermsPersonal] = useState<any>({} as any);
    const [params, setParams] = useState<any>({ page: 1 });
    const [pages, setPages] = useState<PagesPaginateModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    
    const service = new TermsPersonalService();

    const handleList = useCallback(async () => {

        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.listPendency(params);

            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            setTermsPersonal(_Response.data);
            setPages(_Response?.pages);
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os termos.');
        }
    }, [params]);

    const handleSaveFile = useCallback(async (file: any, id: number) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.saveFile(file, id);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }
            
            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os termos.');
        }
    }, []);

    const handleStartDocument = useCallback(async (data: any) => {
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.startDocument(data);
            setIsLoading(false);

            if (!!_Error) {
                return setError(_Response?.message || _Error);
            }

            return setError('');
        } catch (e) {
            setIsLoading(false);
            setError('Não foi possível carregar os termos.');
        }
    }, []);

    useEffect(() => {
        !!error && toast.error(error);
    }, [error]);

    return (
        <TermsPersonalContext.Provider value={{
            isLoadingFile,
            termsPersonal,
            setTermsPersonal,
            params,
            setParams,
            pages,
            setPages,
            isLoading,
            error,
            setError,
            handleList,
            handleSaveFile,
            handleStartDocument
        }}>
            {children}
        </TermsPersonalContext.Provider>
    );
}

export const useTermsPersonal = () => useContext(TermsPersonalContext);