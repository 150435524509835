import { FC, useEffect } from 'react';
import './ExportButtons.scss';
import Swal from 'sweetalert2';

interface ExportButtonsProps {
    getExcel?: () => void;
    getExcelAddtional?: () => void;
    getPdf?: () => void;
    isLoading?: boolean;
    error?: string;
}

const ExportButtons: FC<ExportButtonsProps> = ({ getExcel, getExcelAddtional, getPdf, isLoading, error = '' }: ExportButtonsProps) => {
    useEffect(() => {
        if (isLoading) {
            Swal.fire({
                title: "<p class='title-swal m-0'>Solicitando Relatório<br/>Aguarde que avisaremos<br/>quando tudo estiver pronto.</p>",
                allowOutsideClick: false
            });
            Swal.showLoading();
        } else {
            if (error) {
                Swal.fire(`<p class='title-swal m-0'>${error}</p>`);
                Swal.hideLoading();
            } else {
                Swal.close();
            }
        }
    }, [isLoading]);

    return (
        <div className="ExportButtons" data-testid="ExportButtons">
            <div className="btn-group d-flex gap-2">
                {getExcel && (
                    <button className="btn bg-success text-white rounded" disabled={isLoading}
                        onClick={getExcel}>
                        <i className="fas fa-file-excel me-1" /> Excel
                    </button>
                )}

                {getExcelAddtional && (
                    <button className="btn bg-success text-white rounded" disabled={isLoading}
                        onClick={getExcelAddtional}>
                        <i className="fas fa-file-excel me-1" /> Excel Sintético
                    </button>
                )}

                {getPdf && (
                    <button className="btn bg-danger text-white rounded" disabled={isLoading} onClick={getPdf}>
                        <i className="fas fa-file-pdf me-1" /> PDF
                    </button>
                )}
            </div>
        </div>
    );
}

export default ExportButtons;
