import { FC, useEffect, useRef, useState } from "react";
import "./ModalTermsInsert.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Card, Col, FormCheck, FormGroup, FormLabel, Row } from "react-bootstrap";
import { schema } from "./Validate";
import ReactSelect from "react-select";
import { TermsDocumentsParams } from "../../../models/Terms/TermsDocumentsParams";
import { useTermsDocuments } from "../../../providers/Terms/TermsDocumentsProvider";
import { customStyles } from "../../../models/SelectCustomStyles";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import {
  TermsDocumentsTagsModel,
  TermsTagsColumnsTablesModel,
} from "../../../models/Terms/TermsDocumentsTagsModel";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { TermsDocumentsTagsDescModel } from "../../../models/Terms/TermsDocumentsTagsDescModel";
import InputSearchPartner from "../../Registrations/Partner/InputSearchPartner/InputSearchPartner";
import ReactQuillTextEditor from "../../ReactQuillTextEditor/ReactQuillTextEditor";
import { useAuth } from "../../../providers/AuthProvider";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

interface ModalTermsInsertProps {
  show: boolean;
  onClose: () => void;
}

export const defaultValues: TermsDocumentsParams = {};

const ModalTermsInsert: FC<ModalTermsInsertProps> = ({ show, onClose }) => {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const quillRef = useRef<any>(null);
  const {termsDepartamentOptions, handleSave,handleList, setError, params, term, setTerm,
    tags,tagsDesc, handleListGetDocument, documents, handleUpdate, termsChannelsOptions,
    termsEnterpriseOptions, termsSuppliersOptions, handleTermsChannels, handleListTag, handleTermsSuppliers, 
    handleTermsEnterprise
  } = useTermsDocuments();
  const [exclusive, setExclusive] = useState<any>();
  const [automaticShipping, setAutomaticShipping] = useState<any>();
  const [blockAccess, setBlockAccess] = useState<any>();
  const [justAccept, setJustAccept] = useState<any>();
  const [releaseMasterSignature, setReleaseMasterSignature] = useState<any>();
  const [releasePartnerBusiness, setReleasePartnerBusiness] = useState<any>();
  const [releasePartnerEmployee, setReleasePartnerEmployee] = useState<any>();
  const [visiblePartner, setVisiblePartner] = useState<any>();
  const [withLogo, setWithLogo] = useState<any>();
  const [withWitnesses, setWithWitnesses] = useState<any>();
  const [valuePartner, setValuePartner] = useState<any>();
  const [selectedDepartament, setSelectedDepartament] = useState<any>();
  const [selectedEnterprise, setSelectedEnterprise] = useState<any>();
  const [selectedChannels, setSelectedChannels] = useState<any>();
  const [selectedSuppliers, setSelectedSuppliers] = useState<any>();
  const [tagsValues, setTagsValues] = useState<any>(null);
  const tagifyRef = useRef<any>(null);
  const [tagsBlur, setTagsBlue] = useState<[]>([]);
  const [valueDf, setValueDf] = useState<string | undefined>("");
  const { control} = useForm<any>({ defaultValues });
  const [editorHtml, setEditorHtml] = useState<string>('');

  const { onInvalid } = useAuth();
  
  const { handleSubmit, register, setValue, reset, formState: { errors }, } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setError("");
    setTerm({});
    onClose();
  };

  useEffect(()=>{
    handleListTag();
    handleTermsSuppliers();
    handleTermsEnterprise();
  },[]);

  useEffect(()=>{
    setValue("htmlContent", editorHtml);
  },[editorHtml]);

  useEffect(() => {
    setValue('enterprises', selectedEnterprise);
  }, [selectedEnterprise]);

  useEffect(() => {
    setValue('termDocumentSignatureChannel', selectedChannels);
  }, [selectedChannels]);

  useEffect(() => {
    setValue('suppliers', selectedSuppliers);
  }, [selectedSuppliers]);

  useEffect(() => {
    setValue('htmlContent', editorHtml);
  }, [editorHtml]);

  const oninvalide = async (data: any) => {
    console.log('oninvalide: ', data);
  }

  const onSubmit = async (data: any) => {
    setIsLoadingSubmit(true);
    setError("");

    data.termDocumentSignatureChannel = selectedChannels?.map((x: any) => x.value);
    data.enterprises = selectedEnterprise?.map((x: any) => x.value);
    if(data.userId){data.userId = data.userId.value};
    
    setValueDf(documents);
    setValue("termsTagsColumnsTablesIds", tagsBlur);
    data.withWitnesses = data?.withWitnesses ? 1 : 0;
    data.automaticShipping = data?.automaticShipping ? 1 : 0;
    data.blockAccess = data?.blockAccess ? 1 : 0;
    data.justAccept = data?.justAccept ? 1 : 0;
    data.releasePartnerBusiness = data?.releasePartnerBusiness ? 1 : 0;
    data.releasePartnerEmployee = data?.releasePartnerEmployee ? 1 : 0;
    data.releaseMasterSignature = data?.releaseMasterSignature ? 1 : 0;
    data.withLogo = data?.withLogo ? 1 : 0;
    data.visiblePartner = data?.visiblePartner ? 1 : 0;
    data.hasExclusive = data?.hasExclusive ? 1 : 0;
    
    if (term?.id) {
      const updt = {
        name: data.name,
        hasExclusive: data.hasExclusive,
        termDocumentDepartmentId: data.termDocumentDepartmentId,
        htmlContent: data.htmlContent,
        withWitnesses: data.withWitnesses,
        visiblePartner: data.visiblePartner,
        blockAccess: data.blockAccess,
        releasePartnerBusiness: data.releasePartnerBusiness,
        releasePartnerEmployee:data.releasePartnerEmployee,
        releaseMasterSignature: data.releaseMasterSignature,
        justAccept: data.justAccept,
        withLogo: data.withLogo,
        automaticShipping: data.automaticShipping,
        enterprises: data.enterprises
      };
      const ret = await handleUpdate(updt, term?.id);

      if (ret) {
        toast.success("Termo alterado com sucesso!");
        handleList(params);
        handleClose();
      }
    } else {
      const ret = await handleSave(data as any);

      if (ret) {
        toast.success("Termo salvo com sucesso!");
        handleList(params);
        handleClose();
      }
    }
    setIsLoadingSubmit(false);
  };

  useEffect(() => {
    const tagsList = tagsDesc?.map((x: TermsDocumentsTagsDescModel) => {
      return { value: x.name, id: x.id };
    });

    setTagsValues(tagsList);
  }, [tagsDesc]);

  useEffect(() => {
    setValue("htmlContent", documents ?? "");
  }, [documents]);

  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.editor.root.style.height = "420px";
    }
    if (show) {
      if (term?.id) {
        setValueDf(documents);
        handleListGetDocument(term?.id);
        setValue("name", term?.name ?? "");
        setValue("termDocumentDepartmentId", term?.termDocumentDepartment?.id ?? 0);
        setValue("htmlContent", term?.htmlContent?? "");
        setEditorHtml(term?.htmlContent?? "");
        setSelectedDepartament({ value: term?.termDocumentDepartment?.id, label: term?.termDocumentDepartment?.name });
        setSelectedEnterprise(term?.enterprises?.map((enterprise) => ({
          value: enterprise.id, label: enterprise.name, })));
        setSelectedSuppliers(term?.termDocumentSignatureChannel?.map((x: any) => ({ value: x.termSupplier.id, label: x.termSupplier.name })));
        setSelectedChannels(term?.termDocumentSignatureChannel?.map((channel) => ({
          value: channel.id, label: channel.name, })));
        term?.hasExclusive === 1 ? setExclusive(true) : setExclusive(false);
        term?.termDocumentConfiguration?.automaticShipping === 1 ? setAutomaticShipping(true) : setAutomaticShipping(false);
        term?.termDocumentConfiguration?.blockAccess === 1 ? setBlockAccess(true) : setBlockAccess(false);
        term?.termDocumentConfiguration?.justAccept === 1 ? setJustAccept(true) : setJustAccept(false);
        term?.termDocumentConfiguration?.releaseMasterSignature === 1 ? setReleaseMasterSignature(true) : setReleaseMasterSignature(false);
        term?.termDocumentConfiguration?.releasePartnerBusiness === 1 ? setReleasePartnerBusiness(true) : setReleasePartnerBusiness(false);
        term?.termDocumentConfiguration?.releasePartnerEmployee === 1 ? setReleasePartnerEmployee(true) : setReleasePartnerEmployee(false);
        term?.termDocumentConfiguration?.visiblePartner === 1 ? setVisiblePartner(true) : setVisiblePartner(false);
        term?.termDocumentConfiguration?.withLogo === 1 ? setWithLogo(true) : setWithLogo(false);
        term?.termDocumentConfiguration?.withWitnesses === 1 ? setWithWitnesses(true) : setWithWitnesses(false);

      } else {
        setValueDf("");
        reset();
        setValue("htmlContent", "");
        setValue("name", "");
        setSelectedDepartament(null);
        setSelectedChannels(null);
        setSelectedSuppliers(null);
        setSelectedEnterprise(null);
        setExclusive(false);
        setAutomaticShipping(false);
        setBlockAccess(false);
        setJustAccept(false);
        setReleaseMasterSignature(false);
        setReleasePartnerBusiness(false);
        setReleasePartnerEmployee(false);
        setVisiblePartner(false);
        setWithLogo(false);
        setWithWitnesses(false);
        setValuePartner('');
        setEditorHtml('');
      }
    }
  }, [show]);

  return (
    <ModalDefault
      title={"Cadastrar Documento"}
      show={show}
      onClose={handleClose}
      sizeModal={"xxl"}
      showFooter={true}
      buttonText={isLoadingSubmit ? "Salvando..." : "Salvar"}
      showButtonSpinner={isLoadingSubmit}
      disabledSubmit={isLoadingSubmit}
      handleSubmit={handleSubmit(onSubmit, oninvalide)}
      backdrop="static"
      backgroundColor="#f8f9fa"
    >
      <div className="ModalTermsInsert" data-testid="ModalTermsInsert">
        <Row>
          <Col md={8} sm={12}>
            <Card
              style={{
                maxHeight: "660px",
                minHeight: "660px",
                display: "flex",
              }}
              className="p-3"
            >
              <FormGroup className="form-group">
                <label>TÍTULO DO DOCUMENTO</label>
                <input
                  className={`form-control ${onInvalid(errors?.name)}`}
                  type="text"
                  defaultValue={term?.name ?? ""}
                  {...register('name')}
                  onChange={(e) => {
                    setValue("name", e.target.value);
                  }}
                />
                <ErrorMessage name="Nome" type={errors?.name?.type?.toString()} />
              </FormGroup>

              <FormGroup className="form-group d-flex align-items-center">
                <div style={{ width: "50%" }}>
                  <label htmlFor="departament">DEPARTAMENTO</label>
                  <ReactSelect
                    isClearable
                    isSearchable
                    options={termsDepartamentOptions}
                    value={selectedDepartament}
                    defaultValue={selectedDepartament}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                    {...register('termDocumentDepartmentId')}
                    onChange={(val: any) => {
                      setSelectedDepartament(val);
                      setValue("termDocumentDepartmentId", val.value);
                    }}
                  />
                  <ErrorMessage name="Departamento" type={errors?.termDocumentDepartmentId?.type?.toString()} />
                </div>
                
                <div style={{ width: "50%" }} className="ps-2">
                  <label htmlFor="enterprises">EMPRESAS</label>
                  <ReactSelect
                    isClearable
                    isMulti
                    isSearchable
                    options={termsEnterpriseOptions}
                    value={selectedEnterprise}
                    defaultValue={selectedEnterprise}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    {...register('enterprises')}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                    onChange={(val: any) => {
                      setSelectedEnterprise(val);
                    }}
                  />
                  <ErrorMessage name="Empresa" type={errors?.enterprises?.type?.toString()} />
                </div>

                <div style={{ width: "50%" }} className="ps-2">
                  <label htmlFor="suppliers">FORNECEDOR</label>
                  <ReactSelect                 
                    isSearchable
                    options={termsSuppliersOptions}
                    value={selectedSuppliers}
                    defaultValue={selectedSuppliers}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    {...register('suppliers')}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                    onChange={(val: any) => {
                      setSelectedSuppliers(val);
                      handleTermsChannels(val.value); 
                    }}
                  />
                  <ErrorMessage name="Fornecedor" type={errors?.suppliers?.type?.toString()} />
                </div>

                <div style={{ width: "50%" }} className="ps-2">
                  <label htmlFor="chanels">CANAIS</label>
                  <ReactSelect
                    isMulti
                    isClearable
                    isSearchable
                    isDisabled={!selectedSuppliers}
                    options={termsChannelsOptions}
                    value={selectedChannels}
                    defaultValue={selectedChannels}
                    placeholder="Selecione..."
                    className={`form-control p-0`}
                    {...register('termDocumentSignatureChannel')}
                    noOptionsMessage={() => "Não há registros"}
                    styles={customStyles}
                    onChange={(val: any) => {
                      setSelectedChannels(val);
                    }}
                  />
                  <ErrorMessage name="Canais" type={errors?.termDocumentSignatureChannel?.type?.toString()} />
                </div>                
              </FormGroup>

              <div>
                <ReactQuillTextEditor 
                    editorHtml={editorHtml} 
                    setEditorHtml={setEditorHtml}
                    {...register('htmlContent')}
                />
                <ErrorMessage name="Conteúdo" type={errors?.htmlContent?.type?.toString()} />  
              </div>
            </Card>
          </Col>

          <Col md={4} sm={12}>
            <Card className="p-2 mt-1 mb-1" style={{ display: "flex" }}>
              {tags?.map((x: TermsDocumentsTagsModel) => (
                <div>
                  <b>#</b> TAGS - {x?.name}
                  <br />
                  <hr />
                  <ul
                    style={{
                      columnCount: "2",
                      columnGap: "30px",
                      padding: 0,
                      listStyleType: "none",
                    }}
                  >
                    {x?.termsTagsColumnsTables?.map(
                      (t: TermsTagsColumnsTablesModel) => (
                        <li>{t?.termTag?.name}</li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </Card>

            <Card className="p-2 mt-1 mb-1" style={{ display: "flex" }}>
              <table>
                <thead>
                  Configurações
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b className="text-sm">COM TESTEMUNHA?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('withWitnesses')}
                          onClick={() => {setWithWitnesses((current: any) => !current)}}
                          checked={withWitnesses}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">BLOQUEAR?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('blockAccess')}
                          onClick={() => {setBlockAccess((current: any) => !current)}}
                          checked={blockAccess}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">SÓCIO ASSINA?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('releasePartnerBusiness')}
                          onClick={() => {setReleasePartnerBusiness((current: any) => !current)}}
                          checked={releasePartnerBusiness}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">FUNCIONÁRIO ASSINA?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('releasePartnerEmployee')}
                          onClick={() => {setReleasePartnerEmployee((current: any) => !current)}}
                          checked={releasePartnerEmployee}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">MASTER ASSINA?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('releaseMasterSignature')}
                          onClick={() => {setReleaseMasterSignature((current: any) => !current)}}
                          checked={releaseMasterSignature}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">TIPO ACEITE?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('justAccept')}
                          onClick={() => {setJustAccept((current: any) => !current)}}
                          checked={justAccept}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">COM LOGOTIPO?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('withLogo')}
                          onClick={() => {setWithLogo((current: any) => !current)}}
                          checked={withLogo}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">ENVIO AUTOMÁTICO?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('automaticShipping')}
                          onClick={() => {setAutomaticShipping((current: any) => !current)}}
                          checked={automaticShipping}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">VISÍVEL AO PARCEIRO?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('visiblePartner')}
                          onClick={() => {setVisiblePartner((current: any) => !current)}}
                          checked={visiblePartner}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b className="text-sm">É EXCLUSIVO?</b>
                    </td>
                    <td> 
                      <FormCheck className="form-check form-switch d-flex justify-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          {...register('hasExclusive')}
                          onClick={() => {setExclusive((current: any) => !current)}}
                          checked={exclusive}
                        />
                      </FormCheck>
                    </td>
                  </tr>
                  <tr>
                    {exclusive && 
                    <td className="text-wrap text-center align-middle">
                      <label htmlFor="userId">AGENTE</label>
                      <br />
                      <InputSearchPartner
                        name="userId"
                        control={control}
                        setValue={setValue}
                        valuePartner={valuePartner}
                        setValuePartner={setValuePartner}
                      />
                    </td>
                  }
                  </tr>
                </tbody>
              </table>

            </Card>
          </Col>
        </Row>
      </div>
    </ModalDefault>
  );
};

export default ModalTermsInsert;
