import { FC, useEffect, useState } from "react";
import MasterPage from "../../../components/MasterPage/MasterPage";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFileLines, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import PageBase from "../../../components/PageBase/PageBase";
import { useTermsPersonal } from "../../../providers/Terms/TermsPersonalProvider";
import { useTermsTracking } from "../../../providers/Terms/TermsTrackingProvider";
import React from "react";
import FormatCpfCnpj from "../../../utils/FormatCpfCnpj";
import FormatPhone from "../../../utils/FormatPhone";
import moment from "moment";
import returnBadge from "../../../utils/BadgeTermStatus";
import DropdownMenuTable from "../../../components/DropdownMenuTable/DropdownMenuTable";
import SkeletonTable from "../../../components/SkeletonTable/SkeletonTable";
import AppPagination from "../../../components/AppPagination/AppPagination";
import { usePaginate } from "../../../providers/PaginateProvider";
import ModalSignature from "../../../components/Terms/TermsTracking/ModalSignature/ModalSignature";
import { useSidebarFilter } from "../../../providers/SidebarFilterProvider";
import SidebarTermsPersonalFilter from "../../../components/Terms/SidebarTermsPersonalFilter/SidebarTermsPersonalFilter";
import { useAuth } from "../../../providers/AuthProvider";

interface TermsPersonalProps {}

const TermsPersonal: FC<TermsPersonalProps> = () => {

  const [file, setFile] = useState<File | null>(null);
  const [showModalSignature, setShowModalSignature] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const {isLoadingFile, handleList, params, setParams, isLoading, error, pages, termsPersonal, 
    handleSaveFile, handleStartDocument} = useTermsPersonal();
  const { termsTrackings, handleList: handleListTracking, handleTermSignature, params: paramsTracking,
    setParams: setParamsTracking
    } = useTermsTracking();
  const { handlePaginate } = usePaginate();
  const { showSidebarFilter, setShowSidebarFilter } = useSidebarFilter();
  const { userLogged } = useAuth();

  useEffect(() => {
    setParamsTracking({...paramsTracking, userId: userLogged?.accessProfile?.id});

    handleList();
    handleListTracking();
  }, [params]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, id: number) => {
    e.preventDefault();
    if (file) {
      handleSaveFile(file, id);
    }
  };

  const handleDocument = (dados: any) => {
    handleStartDocument({id: dados.id, supplier: 1, version: 1});
    
    handleList();
    handleListTracking();
  };

  const renderParty = (data:any, property:string) => {
    let party = data?.filter((item:any) => item.type == 'party')?.shift() || null
    
    if (property == 'email'){
      return party?.email || '';
    }

    return FormatPhone(party?.phone) || '';
  }

  const getTermType = (hasPhysicalDocument: number, envelopeId: any, documentSupplierId: any): string => {
    if (hasPhysicalDocument === 1) {
      return 'FÍSICO';
    }
    if (hasPhysicalDocument === 0 && !envelopeId && !documentSupplierId) {
      return 'ACEITE';
    }
    return 'DIGITAL';
  };

  const onClickModalSignature = (termId: number) => {
    handleTermSignature(termId);
    setShowModalSignature(true)
  }

  const handleAttachment = (item: any) => {

    setExpandedRows((x) =>
      x.includes(item?.id)
        ? x.filter((row) => row !== item?.id)
        : [...x, item?.id]
    );
  }

  const renderListAvailable = (): JSX.Element => {
    return (
      <>
        {termsPersonal?.length > 0 ? (
          <>
            {termsPersonal?.map((x: any, key: number) => (
              <React.Fragment key={x?.id}>
                <tr className={`text-sm 'no-border'`}>
                  <td>
                    {x?.name}
                  </td>
                  <td>
                    {x?.termDocumentDepartment?.name.toUpperCase()}
                  </td>
                  <td>
                    {x?.termDocumentSignatureChannel?.length > 0 && (
                      <>
                        <ul className='mb-0' style={{listStyleType: 'disclosure-closed'}}>
                          {x?.termDocumentSignatureChannel?.map((channels:any) => (
                            <li>
                              {`${channels?.name}`}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                  <td style={{textAlign: 'right'}}>
                    <button type="button" className="btn m-0 btn-primary" onClick={() => handleDocument(x)}>
                      INICIAR
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={9} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  };

  const renderList = (): JSX.Element => {
    return (
      <>
        {termsTrackings?.length > 0 ? (
          <>
            {termsTrackings?.map((x: any, key: number) => (
              <React.Fragment key={x?.id}>
                <tr className={`text-sm ${expandedRows.includes(x?.id) ? 'no-border' : ''}`}>
                  <td>
                    <small className='font-italic font-weight-bold'> Parceiro: </small> {x?.user?.partnerCode} - {x?.user?.name?.toUpperCase()} <br />
                    <small className='font-italic font-weight-bold'> CPF: </small> {FormatCpfCnpj(x?.user?.registrationData?.cpf)}<br />
                    <small className='font-italic font-weight-bold'> E-mail: </small> {renderParty(x?.termSubscriber, 'email')} <br />
                    <small className='font-italic font-weight-bold'> Telefone: </small> {renderParty(x?.termSubscriber, 'phone')}
                  </td>
                  <td>
                    <small className='font-italic font-weight-bold'> Título do termo:</small> {x?.termDocument?.name?.toUpperCase()} <br />
                    <small className='font-italic font-weight-bold'> Fornecedor:</small> {x?.termDocument?.termDocumentSignatureChannel[0]?.termSupplier?.name} <br />
                    <small className='font-italic font-weight-bold'> Criação: </small> {moment(x?.createdAt).format("DD/MM/YYYY HH:mm")} <br />
                    <span className='text-danger'> <small className='font-italic font-weight-bold'> Expiração: </small> {moment(x?.createdAt).add(7, 'd').format("DD/MM/YYYY")} </span> <br />
                  </td>
                  <td>
                    {x?.termDocument?.termDocumentSignatureChannel?.length > 0 && (
                      <>
                        <ul className='mb-0' style={{listStyleType: 'disclosure-closed'}}>
                          {x?.termDocument?.termDocumentSignatureChannel?.map((channels:any) => (
                            <li>
                              {`${channels?.name}`}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                  <td className='text-center'>{getTermType(x?.hasPhysicalDocument, x?.envelopeId, x?.documentSupplierId)}</td>
                  <td className='text-center'>
                    <span className={`badge ${returnBadge(x?.termStatus?.id)}`}>
                      {x?.termStatus?.name}
                    </span>
                  </td>
                  <td className="text-center">
                    {x?.termStatus?.id != 4 && 
                      (<DropdownMenuTable key={key}>
                        <>
                          {x.hasPhysicalDocument == 1 && x.termStatus.id == 2 && 
                            <Dropdown.Item
                              className="border-radius-md py-2"
                              onClick={() => handleAttachment(x)}
                            >
                              <FontAwesomeIcon icon={faFolderOpen} className="me-2" /> Anexar Arquivo
                            </Dropdown.Item>
                          }
                        </>
                        {
                          <Dropdown.Item
                            className="border-radius-md py-2"
                            onClick={() => onClickModalSignature(x?.id)}
                          >
                            <FontAwesomeIcon icon={faFileLines} className="me-2" /> Visualizar Assinaturas
                          </Dropdown.Item>
                        }
                      </DropdownMenuTable>
                      )}
                  </td>
                </tr>

                {expandedRows.includes(x?.id) && (
                 <tr className="collapse-content">
                    <td colSpan={9}>
                      <div className="p-3 bg-light border-radius-md expanded-content">
                      <Form onSubmit={(e: any) => handleSubmit(e, x?.id)}>
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Selecione um arquivo</Form.Label>
                          <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                          Upload
                        </Button>
                      </Form>
                      </div>
                    </td>
                  </tr> 
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={9} className="text-center text-sm">Não há registros</td>
          </tr>
        )}
      </>
    );
  };

  const renderContent = (): JSX.Element => {
    return (
      <>
        {!isLoading ? (
          <div>
            <div className="table-responsive">
              <div className="card-body d-flex justify-content">
                <h5>
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <b className="ps-2">Documentos Disponíveis</b>
                </h5>
              </div>
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7" scope="col">Nome</th>
                    <th className="text-uppercase opacity-7" scope="col">Departamento</th>
                    <th className="text-uppercase opacity-7" scope="col">Canais de assinatura</th>
                    <th className="text-uppercase opacity-7" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {renderListAvailable()}
                </tbody>
              </table>
            </div>


            <div className="table-responsive">
              <div className="card-body d-flex justify-content">
                <h5>
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <b className="ps-2">Documentos Encontrados</b>
                </h5>
              </div>
              <table className="table table-flush m-0 w-100">
                <thead className="thead-light">
                  <tr>
                    <th className="text-uppercase opacity-7" scope="col">Agente Solicitante</th>
                    <th className="text-uppercase opacity-7" scope="col">Dados do Termo</th>
                    <th className="text-uppercase opacity-7" scope="col">Canais de assinatura</th>
                    <th className="text-uppercase opacity-7" scope="col">Tipo de Documento</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Status</th>
                    <th className="text-uppercase opacity-7 text-center" scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {renderList()}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <SkeletonTable />
        )}

        {termsTrackings?.length > 0 && (
          <div>
            <nav aria-label="...">
              <AppPagination
                pages={pages}
                handlePaginate={handlePaginate}
                params={params}
                setParams={setParams}
                style={{ backgroundColor: '#fff' }}
              />
            </nav>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <MasterPage title="Personal" icon="fas fa-industry" footerFixed footerInverse>
        <div className="TermsPersonal" data-testid="TermsPersonal">
          <PageBase
            title="Gerenciamento de Termo Pessoal"
            subtitle="Gerenciamento de Documentos de Termos Pessoais."
            error={error}
            content={renderContent()}
            handleFilter={() => !!showSidebarFilter ? setShowSidebarFilter(false) : setShowSidebarFilter(true)}
            hasFilter
          />
        </div>
      </MasterPage>

      <ModalSignature
        show={showModalSignature}
        onClose={() => setShowModalSignature(false)}
      />

      <SidebarTermsPersonalFilter/>
    </>
  );
};

export default TermsPersonal;
