import { createContext,  Dispatch, ReactNode,  SetStateAction,  useCallback,  useContext,  
  useEffect,  useMemo,  useState} from "react";
import { ReactSelectOptions } from "../../models/ReactSelectOptions";
import { toast } from "react-toastify";
import { TermsDocumentsDepartamentModel } from "../../models/Terms/TermsDocumentsDepartamentModel";
import { TermsDocumentsService } from "../../services/Terms/TermsDocumentsService";
import { PagesPaginateModel } from "../../models/Paginate/PagesPaginate";
import { TermsDocumentsParams } from "../../models/Terms/TermsDocumentsParams";
import { TermsDocumentsTypePersonModel } from "../../models/Terms/TermsDocumentsTypePersonModel";
import { TermsDocumentsStatusModel } from "../../models/Terms/TermsDocumentsStatusModel";
import { EnterpriseModel, TermsDocumentsModel,
  TermsDocumentsUpdateModel} from "../../models/Terms/TermsDocumentsModel";
import { ToastSettings } from "../../utils/ToastSettings";
import { TermsDocumentsTagsModel } from "../../models/Terms/TermsDocumentsTagsModel";
import { TermsDocumentsTagsDescModel } from "../../models/Terms/TermsDocumentsTagsDescModel";
import { TermsDocumentsChannelsModel } from "../../models/Terms/TermsDocumentsChannelsModel";
import { TermsDocumentsTotalsModel } from "../../models/Terms/TermsDocumentsTotalsModel";
import { EnterpriseService } from "../../services/Registrations/Company/EnterpriseService/EnterpriseService";
import { TermsDocumentsSuppliersModel } from "../../models/Terms/TermsDocumentsSuppliersModel";
import { TermsDocumentsRulesModel } from "../../models/Terms/TermsDocumentsRulesModel";
import { TermsDocumentsRuleModel } from "../../models/Terms/TermsDocumentsRuleModel";

interface ContextProps {
  terms: TermsDocumentsModel[];
  tags: TermsDocumentsTagsModel[];
  documents: string | undefined;
  tagsDesc: TermsDocumentsTagsDescModel[];
  term: TermsDocumentsModel;
  setTerm: Dispatch<SetStateAction<TermsDocumentsModel>>;
  termsDepartament: TermsDocumentsDepartamentModel[];
  termsDepartamentOptions: ReactSelectOptions[];
  handleTermsDepartament: () => void;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  params: TermsDocumentsParams;
  setParams: Dispatch<SetStateAction<TermsDocumentsParams>>;
  termsStatus: TermsDocumentsStatusModel[];
  termsStatusOptions: ReactSelectOptions[];
  handleList: (params: TermsDocumentsParams) => void;
  isLoading: boolean;
  pages: PagesPaginateModel;
  handleSave: (data: TermsDocumentsModel) => Promise<boolean>;
  handleUpdate: (
    data: TermsDocumentsUpdateModel,
    id: number
  ) => Promise<boolean>;
  handleListTag: () => void;
  handleDelete: (id: number) => Promise<boolean>;
  handleListTagDesc: () => void;
  handleListGetDocument: (id: number) => void;
  handleTermsChannels: (id: any) => void;
  termsChannelsOptions: ReactSelectOptions[];
  handleTermsSuppliers: () => void;
  termsSuppliersOptions: ReactSelectOptions[];
  handleTermsTotals: () => void;
  termsTotals: TermsDocumentsTotalsModel;
  handleTermsEnterprise: () => void;
  termsEnterpriseOptions: ReactSelectOptions[];
  rules: TermsDocumentsRulesModel[];
  handleTermsRules: (id: number | undefined) => void;
  handleSaveRule: (data: TermsDocumentsRuleModel) => Promise<boolean>;
  handleDeleteRules: (id: number) => Promise<boolean>;
}

export const TermsDocumentsContext = createContext<ContextProps>(
  {} as ContextProps
);

interface TermsDocumentsProviderProps {
  children: ReactNode;
}

export const TermsDocumentsProvider = ({
  children,
}: TermsDocumentsProviderProps) => {
  const [terms, setTerms] = useState<TermsDocumentsModel[]>([]);
  const [rules, setRules] = useState<TermsDocumentsRulesModel[]>([]);
  const [tags, setTags] = useState<TermsDocumentsTagsModel[]>([]);
  const [documents, setDocuments] = useState<string>();
  const [tagsDesc, setTagsDesc] = useState<TermsDocumentsTagsDescModel[]>([]);
  const [term, setTerm] = useState<TermsDocumentsModel>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pages, setPages] = useState<PagesPaginateModel>({});
  const [params, setParams] = useState<TermsDocumentsParams>({});
  const [error, setError] = useState<string>("");
  const [termsDepartament, setTermsDepartament] = useState<TermsDocumentsDepartamentModel[]>([]);
  const [termsDepartamentOptions, setTermsDepartamentOptions] = useState<ReactSelectOptions[]>([]);
  const [termsChannels, setTermsChannels] = useState<TermsDocumentsChannelsModel[]>( [] );
  const [termsSuppliers, setTermsSuppliers] = useState<TermsDocumentsSuppliersModel[]>( [] );
  const [termsTotals, setTermsTotals] = useState<TermsDocumentsTotalsModel>({});
  const [termsChannelsOptions, setTermsChannelsOptions] = useState<ReactSelectOptions[]>([]);
  const [termsSuppliersOptions, setTermsSuppliersOptions] = useState<ReactSelectOptions[] >([]);
  const [termsStatus, setTermsStatus] = useState<TermsDocumentsStatusModel[]>([]);
  const [termsStatusOptions, setTermsStatusOptions] = useState<ReactSelectOptions[]>([]);
  const [termsEnterprise, setTermsEnterprise] = useState<EnterpriseModel[]>( [] );
  const [termsEnterpriseOptions, setTermsEnterpriseOptions] = useState< ReactSelectOptions[] >([]);

  const service = new TermsDocumentsService();
  const serviceEnterprise = new EnterpriseService();

  const handleSave = async (data: TermsDocumentsModel) => {
    try {
      const [_Response, _Error, _Code, _Errors] = await service.save(data);
      if (!!_Error) {
        ToastSettings(
          _Response?.message || _Error,
          "bottom-center",
          "error",
          () => {},
          _Errors
        );
        return false;
      }
      setError("");
      return true;
    } catch (e) {
      setError("Houve um erro ao salvar o termo");
      return false;
    }
  };

  const handleUpdate = async (data: TermsDocumentsModel, id: number) => {
    try {
      const [_Response, _Error, _Code, _Errors] = await service.update(data,id);
      if (!!_Error) {
        ToastSettings(
          _Response?.message || _Error,
          "bottom-center",
          "error",
          () => {},
          _Errors
        );
        return false;
      }
      setError("");
      return true;
    } catch (e) {
      setError("Houve um erro ao salvar o termo");
      return false;
    }
  };

  const handleSaveTag = async (
    data: TermsDocumentsModel,
    id: number | undefined
  ) => {
    try {
      const [_Response, _Error, _Code, _Errors] = await service.saveTag(
        data,
        id
      );
      if (!!_Error) {
        ToastSettings(
          _Response?.message || _Error,
          "bottom-center",
          "error",
          () => {},
          _Errors
        );
        return false;
      }

      setError("");
      return true;
    } catch (e) {
      setError("Houve um erro ao salvar o termo");
      return false;
    }
  };

  const handleTermsRules = useCallback(async (id:number | undefined) => {
      try {
        const [_Response, _Error] = await service.listRules(id);

        if (_Error) {
          return setError(_Response?.message || _Error);
        }

        setRules(_Response.data);
        return setError("");
      } catch (e) {
        return setError("Não foi possível carregar as regras");
      }
    }, []
  );

  const handleList = useCallback(async (params: TermsDocumentsParams) => {
      try {
        setIsLoading(true);
        const [_Response, _Error] = await service.list({...params, withPaginate: true});
        setIsLoading(false);
        
        if (_Error) {
          return setError(_Response?.message || _Error);
        }

        setTerms(_Response.data);
        setPages(_Response.pages);
        return setError("");
      } catch (e) {
        return setError("Não foi possível carregar os termos");
      }
    },
    [params]
  );

  const handleTermsSector = useCallback(async () => { return setError(''); }, []);

  const handleSaveRule = async (data: TermsDocumentsRuleModel) => {
    try {
      const [_Response, _Error, _Code, _Errors] = await service.saveRule(data);
      if (!!_Error) {
        ToastSettings(
          _Response?.message || _Error,
          "bottom-center",
          "error",
          () => {},
          _Errors
        );
        return false;
      }
      setError("");
      return true;
    } catch (e) {
      setError("Houve um erro ao salvar a rota");
      return false;
    }
  };

  const handleTermsDepartament = useCallback(async () => {
    try {
      const [_Response, _Error] = await service.getTermsDepartament();

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTermsDepartament(_Response.data);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar os departamentos");
    }
  }, []);

  const handleTermsChannels = useCallback(async (id: string) => {
    try {
      const [_Response, _Error] = await service.getTermsChannels(id);

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTermsChannels(_Response.data);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar os canais");
    }
  }, []);

  const handleTermsSuppliers = useCallback(async () => {
    try {
      const [_Response, _Error] = await service.getTermsSuppliers();

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTermsSuppliers(_Response.data);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar os fornecedores");
    }
  }, []);

  const handleTermsTotals = async () => {
    try {
      await fetch("http://localhost:3000/mock/totals.json")
        .then((res) => res.json())
        .then((res) => setTermsTotals(res));
      // const [_Response, _Error] = await service.getTermsTotals();

      // if (_Error) {
      //   return setError(_Response?.message || _Error);
      // }

      // setTermsTotals(_Response.data);
      // return setError("");
    } catch (e) {
      return setError("Não foi possível carregar os totais");
    }
  };

  const handleTermsEnterprise = useCallback(async () => {
    try {
      const [_Response, _Error] = await serviceEnterprise.list({ orderBy: [{ name: 'asc'}] });

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTermsEnterprise(_Response.data);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar os canais");
    }
  }, [params]);

  const handleListGetDocument = useCallback(
    async (id: number) => {
      try {
        const [_Response, _Error] = await service.listDocument(id);

        if (_Error) {
          return setError(_Response?.message || _Error);
        }

        setDocuments(_Response.data.htmlContent);
        return setError("");
      } catch (e) {
        return setError("Não foi possível carregar os documentos");
      }
    },
    [params]
  );

  const handleListTag = useCallback(async () => {
    try {
      const [_Response, _Error] = await service.listTag();

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTags(_Response.data);
      setPages(_Response.pages);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar as tags");
    }
  }, [params]);

  const handleListTagDesc = useCallback(async () => {
    try {
      const [_Response, _Error] = await service.listTags();

      if (_Error) {
        return setError(_Response?.message || _Error);
      }

      setTagsDesc(_Response.data);
      setPages(_Response.pages);
      return setError("");
    } catch (e) {
      return setError("Não foi possível carregar as tags");
    }
  }, [params]);

  const handleDelete = async (id: number) => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.delete(id);
      setIsLoading(false);

      if (!!_Error) {
        setError(_Response?.message || _Error);
        return false;
      }

      setError("");
      return true;
    } catch (e: any) {
      setIsLoading(false);
      setError(e.response?.data?.message || "Houve um erro ao deletar o termo");
      return false;
    }
  };

  const handleDeleteRules = async (id: number) => {
    try {
      setIsLoading(true);
      const [_Response, _Error] = await service.deleteRules(id);
      setIsLoading(false);

      if (!!_Error) {
        setError(_Response?.message || _Error);
        return false;
      }

      setError("");
      return true;
    } catch (e: any) {
      setIsLoading(false);
      setError(e.response?.data?.message || "Houve um erro ao deletar a regra");
      return false;
    }
  };

  useEffect(() => {
    error && toast.error(error);
  }, [error]);

  useEffect(() => {
    handleTermsDepartament();
  }, []);

  useEffect(() => {
    setTermsDepartamentOptions(
      termsDepartament?.map((item: TermsDocumentsDepartamentModel) => {
        return { label: item.name, value: item.id };
      })
    );
  }, [termsDepartament]);

  useEffect(() => {
    setTermsChannelsOptions(
      termsChannels?.map((item: TermsDocumentsChannelsModel) => {
        return { label: item.name, value: item.id };
      })
    );
  }, [termsChannels]);

  useEffect(() => {
    setTermsSuppliersOptions(
      termsSuppliers?.map((item: TermsDocumentsSuppliersModel) => {
        return { label: item.name, value: item.id };
      })
    );
  }, [termsSuppliers]);

  useEffect(() => {
    setTermsEnterpriseOptions(
      termsEnterprise?.map((item: EnterpriseModel) => {
        return { label: item.name, value: item.id };
      })
    );
  }, [termsEnterprise]);

  useEffect(() => {
    setTermsStatusOptions(
      termsStatus?.map((item: TermsDocumentsStatusModel) => {
        return { label: item.name, value: item.id };
      })
    );
  }, [termsStatus]);

  return (
    <TermsDocumentsContext.Provider
      value={useMemo(
        () => ({
          terms,
          tags,
          documents,
          tagsDesc,
          term,
          setTerm,
          termsDepartament,
          termsDepartamentOptions,
          handleTermsDepartament,
          error,
          setError,
          params,
          setParams,
          termsStatus,
          termsStatusOptions,
          handleList,
          isLoading,
          pages,
          handleSave,
          handleUpdate,
          handleListTag,
          handleDelete,
          handleListTagDesc,
          handleListGetDocument,
          handleTermsChannels,
          termsChannelsOptions,
          handleTermsSuppliers,
          termsSuppliersOptions,
          handleTermsTotals,
          termsTotals,
          handleTermsEnterprise,
          termsEnterpriseOptions,
          rules,
          handleTermsRules,
          handleSaveRule,
          handleDeleteRules,
        }),
        [
          terms,
          tags,
          documents,
          tagsDesc,
          term,
          setTerm,
          termsDepartament,
          termsDepartamentOptions,
          handleTermsDepartament,
          error,
          setError,
          params,
          setParams,
          termsStatus,
          termsStatusOptions,
          handleList,
          isLoading,
          pages,
          handleSave,
          handleUpdate,
          handleListTag,
          handleDelete,
          handleListTagDesc,
          handleListGetDocument,
          handleTermsChannels,
          termsChannelsOptions,
          handleTermsSuppliers,
          termsSuppliersOptions,
          handleTermsTotals,
          termsTotals,
          handleTermsEnterprise,
          termsEnterpriseOptions,
          rules,
          handleTermsRules,
          handleSaveRule,
          handleDeleteRules,
        ]
      )}
    >
      {children}
    </TermsDocumentsContext.Provider>
  );
};

export const useTermsDocuments = () => useContext(TermsDocumentsContext);
