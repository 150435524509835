import api from "../Api";
import { BaseService } from "../BaseService";

const URL = 'api/terms';

export class TermsPersonalService extends BaseService {

    async listPendency(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.get(`${URL}/documents/rules/pendency`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async saveFile(file: any, id: number): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`${URL}/send/attachment/phisycal`, {termId: id, file: file}));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }

    async startDocument(params: any): Promise<any[]> {
        try {
            return this.handleResponse(await api.post(`api/integrations/document/save`, params));
        } catch (e: any) {
            return this.catchHandler(e);
        }
    }
}