import { TermsDocumentsModel } from "../../models/Terms/TermsDocumentsModel";
import { TermsDocumentsParams } from "../../models/Terms/TermsDocumentsParams";
import api from "../Api";
import { BaseService } from "../BaseService";

const URL = "api/terms";

export class TermsDocumentsService extends BaseService {
  async list(params: TermsDocumentsParams): Promise<any[]> {
    try {
      return this.handleResponse(await api.post(`${URL}/documents/list`, params));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listTags(): Promise<any[]> {
    try {
      return this.handleResponse(await api.get(`${URL}/tags/list`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listTag(): Promise<any[]> {
    try {
      return this.handleResponse(await api.get(`${URL}/tagsTypes/list`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listDocument(id: any): Promise<any[]> {
    try {
      return this.handleResponse(await api.get(`${URL}/documents/get/${id}`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }
  async getTermsDepartament(): Promise<any[]> {
    try {
      return this.handleResponse(await api.get(`${URL}/departments/list`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async getTermsChannels(id: string): Promise<any[]> {
    try {
      return this.handleResponse(await api.get(`${URL}/suppliers/channels/item/${id}`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async getTermsSuppliers(): Promise<any[]> {
    try {
      return this.handleResponse(await api.post(`${URL}/suppliers/list`, { page: 1, perPage: 10, withPaginate: false }));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async getTermsTypeStatus(): Promise<any[]> {
    try {
      return this.handleResponse(await api.post(`${URL}/status`));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async save(data: any): Promise<any> {
    try {
      return this.handleResponse(await api.post(`${URL}/documents/save`, data));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async update(data: any, id: number): Promise<any> {
    try {
        return this.handleResponse(await api.put(`${URL}/documents/update/${id}`, data));
    } catch (e: any) {
        return this.catchHandler(e);
    }
  }

  async saveTag(data: TermsDocumentsModel, id: any): Promise<any> {
    try {
      return this.handleResponse(
        await api.post(`${URL}/documents/tagsColumnsTables/attach/${id}`, data)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async delete(id: number): Promise<any> {
    try {
      return this.handleResponse(
        await api.delete(`${URL}/documents/delete/${id}`)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async listRules(id: number | undefined): Promise<any[]> {
    try {
      return this.handleResponse(await api.post(`${URL}/documents/rules/list`, 
        {termDocumentId: id, withPaginate: false}));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async saveRule(data: any): Promise<any> {
    try {
      return this.handleResponse(await api.post(`${URL}/documents/rules/save`, data));
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

  async deleteRules(id: number): Promise<any> {
    try {
      return this.handleResponse(
        await api.delete(`${URL}/documents/rules/${id}`)
      );
    } catch (e: any) {
      return this.catchHandler(e);
    }
  }

}
